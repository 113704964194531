
//*****************************************************************************

import * as getFunc from "../common/page.functions.js";
import { getErrorStyleMode } from "../common/page.error.js";

//*****************************************************************************

const srcImgList = [

 "/img/error/error-image-2.png",
 "/img/error/error-image-3.png",
 "/img/error/error-image-4.png",
 "/img/error/error-image-5.png"

];

getFunc.preloadImages(srcImgList);

//*****************************************************************************
