
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrErrorPage = ( function () {

 "use strict";

 const selectBodyElement = document.querySelector("body");

 const selectErrorHeader = document.querySelector("main .wrapper .error-header");
 const selectErrorImage = document.querySelector("main .wrapper .error-image");
 const selectErrorCode = document.querySelector("main .wrapper .error-code");
 const selectErrorInfo = document.querySelector("main .wrapper .error-info");

 const styleModeEnabled = true;

 const styleModeKey = "data:mode";
 const styleModeLight = "light";
 const styleModeDark = "dark";

 let styleMode = null;

 const selectMainContainer = document.querySelector("main");
 
 selectMainContainer.style.setProperty("visibility","hidden");

 const getStyleModeStorage = function() {

  const storageKeyValue = localStorage.getItem(styleModeKey);

  if(storageKeyValue === null) { getStyleModeClient(); } else {

   styleMode = storageKeyValue;

  }

  selectBodyElement.setAttribute("data-theme",styleMode);

 }

 const getStyleModeClient = function() {

  if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {

   localStorage.setItem(styleModeKey,styleModeDark);
   styleMode = styleModeDark;

  } else {

   localStorage.setItem(styleModeKey,styleModeLight);
   styleMode = styleModeLight;

  }

 }

 const showMainContainer = function(elem,anim_class,duration) {

  elem.classList.add(anim_class);
  elem.style.setProperty("animation-duration",duration);
  elem.style.setProperty("visibility","visible");

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(anim_class)) {

    elem.classList.remove(anim_class);
    elem.style.removeProperty("animation-duration",duration);
    elem.removeAttribute("style");
    elem.removeAttribute("class");

    elem.removeEventListener("animationend",animate);

   }

  });

 }

 const setStyleModeToggle = function() {

  const selectDataTheme = selectBodyElement.getAttribute("data-theme");

  if(selectDataTheme == styleModeLight) {

   localStorage.setItem(styleModeKey,styleModeDark);
   selectBodyElement.setAttribute("data-theme",styleModeDark);

  } else {

   localStorage.setItem(styleModeKey,styleModeLight);
   selectBodyElement.setAttribute("data-theme",styleModeLight);

  }

 }

 const init = function() {

  if(styleModeEnabled) { getStyleModeStorage(); }

  showMainContainer(selectMainContainer,"animate_fadeIn","0.4s");

  const selectHomeButton = document.querySelector(".btn-home")

  selectHomeButton.addEventListener("click", function() {

   window.open("/","_parent");

  });

  const selectErrorImage = document.querySelector(".error-image")

  selectErrorImage.addEventListener("click", function() {

   setStyleModeToggle();

  });

 };

 return {

  init : function () { init(); }

 };

});

//*****************************************************************************

const setErrorPage = new ctrErrorPage;

setErrorPage.init();

//*****************************************************************************
